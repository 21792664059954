import React from "react";
import { useQuestionState } from "@core/providers";
import { Question } from "@core/Question";


export const TextEntry: React.FunctionComponent<Question> = ({ Id, Comments }) => {

    const { dispatch } = useQuestionState();

    const updateComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        dispatch({ type: "Update_Comment", data: { id: Id, comment: e.target.value } })
    }

    return <>
        <div className="text-entry" data-testid="text-entry">
            <textarea  value={Comments || ''} onChange={updateComment} placeholder="Write your response here" />
        </div>
    </>
}