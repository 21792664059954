import React from "react";
import { FourScaleOptions, FourScaleOptionsList, ScaleOptionLabels } from "@core/FourScaleOptions"
import { ScaleButton } from "./ScaleButton";
import { useQuestionState } from "@core/providers";
import { FourScaleQuestion } from "@core/Question";


export const FourScale: React.FunctionComponent<FourScaleQuestion> = ({ Id, Answer }) => {

    const { dispatch } = useQuestionState();

    const updateAnswer = (answer: FourScaleOptions) => {
        dispatch({ type: "Update_Answer_FourScale", data: { id: Id, answer } })
    }

    const renderButtons = () => {
        return FourScaleOptionsList.map(option => (
            <ScaleButton key={option}
                onSelected={() => updateAnswer(option)}
                isSelected={Answer === option}>
                {ScaleOptionLabels[option]}
            </ScaleButton>
        ))
    }

    return <>
        <div className="scale-button-holder" data-testid="four-scale">
            {...renderButtons()}
        </div>
    </>
}