import React from "react";
import { Typography } from "@eftours/labs.ui-components"

export const ErrorPage: React.FunctionComponent = () => {
    return <>
        <div className="error-page">
            <Typography className="flex" variant="body1" align="center" >
                An error occured. Please contact TD support.
            </Typography>
        </div>
    </>
}
