import React, { useContext, useReducer } from "react";
import { Question } from "@core/Question";
import { FourScaleOptions } from "@core/FourScaleOptions";
import { BinaryChoiceOptions } from "@core/BinaryChoiceOptions";

interface IQuestionContext {
    questions: QuestionLookup;
    dispatch: (action: Actions) => void;
}
export type QuestionLookup = { [index: number]: Question };
const EmptyQuestions: QuestionLookup = {};

export const QuestionContext = React.createContext<IQuestionContext>({ questions: EmptyQuestions, dispatch: () => null });

type Actions = { type: 'Mark_Complete', data: { id: number } }
    | { type: 'Set_Questions', data: QuestionLookup }
    | { type: 'Update_Comment', data: { id: number, comment: string } }
    | { type: 'Update_Answer_FourScale', data: { id: number, answer: FourScaleOptions } }
    | { type: 'Update_Answer_BinaryChoice', data: { id: number, answer: BinaryChoiceOptions } }


const reducer = (state: QuestionLookup, action: Actions): QuestionLookup => {
    switch (action.type) {
        case "Set_Questions": {
            return action.data;
        }
        case "Mark_Complete": {
            const value = state[action.data.id];
            value.Completed = true;
            return { ...state, [action.data.id]: value }
        }
        case "Update_Comment": {
            const value = { ...state[action.data.id], Comments: action.data.comment };
            return { ...state, [action.data.id]: value }
        }
        case "Update_Answer_FourScale": {
            const value = { ...state[action.data.id], Answer: action.data.answer };
            // This condition will always be true, but we need to it satify the 
            // typechecker
            if (value.Type === "FourScale") {
                return { ...state, [action.data.id]: value }
            }
            break;
        }
        case "Update_Answer_BinaryChoice": {
            const value = { ...state[action.data.id], Answer: action.data.answer };
            // This condition will always be true, but we need to it satify the 
            // typechecker
            if (value.Type === "BinaryChoice") {
                return { ...state, [action.data.id]: value }
            }
            break;
        }
    }
    return state;
};

export const QuestionProvider: React.FC<Partial<IQuestionContext> | undefined> = ({ children, questions }) => {

    const [state, dispatch] = useReducer(reducer, questions || EmptyQuestions);

    return (
        <QuestionContext.Provider value={{ questions: state, dispatch }}  >
            {children}
        </QuestionContext.Provider>
    );
}

export const useQuestionState = () => useContext(QuestionContext);