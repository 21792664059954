import React, {ReactElement} from "react";
import { Typography } from "@eftours/labs.ui-components";


interface IHeaderProps {
    currentQuestionId: number,
    sortedQuestionIds: number[]
    prodTourId: number
}

export function Header({
    currentQuestionId,
    sortedQuestionIds,
    prodTourId
}: IHeaderProps): ReactElement {
    const renderQuestionCounter = () => {
        if (currentQuestionId) {
            const currentIndex = (sortedQuestionIds.indexOf(currentQuestionId || 0)) + 1;

            return <>
                <Typography variant="uilabel">
                    Question {currentIndex}/{sortedQuestionIds.length}
                </Typography>
            </>
        }
    }

    return <>
        <div className="flex space-between">
            <Typography variant="uilabel" color="tertiary">
                {/* Prevent rendering of a 0 while the page loads */}
                {prodTourId ? prodTourId : ''}
            </Typography>
            {renderQuestionCounter()}
        </div>
        <Typography variant="header3">
            Tell us about your tour
        </Typography>
    </>
}

