import { QuestionType } from "@core/Question";
import { BinaryChoiceOptions } from "@core/BinaryChoiceOptions";

const ParagraphText = 5;
const LikertFourScale = 6;
const BooleanAnswer = 7;
export const AnswerTypeMap = (type: number): QuestionType => {
    switch (type) {
        case LikertFourScale:
            return "FourScale";
        case BooleanAnswer:
            return "BinaryChoice";
        case ParagraphText: // Treat answer as text if we don't know the type
        default:
            return "Text";
    }
}






