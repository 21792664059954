import { useQuestionState, QuestionLookup } from "@core/providers";
import { useMemo } from "react";

export const useLastAnsweredQuestion = () => {
  const { questions } = useQuestionState();
  const memoizedSort = useMemo(() => getSortedQuestionSequence(questions), [
    questions
  ]);

  const questionsSorted = memoizedSort.map(id => questions[id]);

  return questionsSorted.find(x => !x.Completed);
};

function getSortedQuestionSequence(questions: QuestionLookup): number[] {
  let sortedSequence = Object.values(questions)
    .sort(q => q.SortOrder)
    .map(q => q.Id);

  return sortedSequence;
}
