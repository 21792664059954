import React from "react";
import { Typography, Button } from "@eftours/labs.ui-components"
import { useQuestionState } from "@core/providers"
import { useHistory, useLocation } from "react-router-dom"
const successImage = require("./TD-TourEndReport-complete.gif");
const successCheckbox = require("./SuccessCheckbox.png");

export const SuccessLayout: React.FunctionComponent = () => {

    const { questions } = useQuestionState()
    const history = useHistory()

    const firstQuestion = Object.values(questions).shift()

    return <>
        <div className="success-page">
            <div className="flex center">
                <img src={successImage} />
            </div>
            <div className="flex center">
                <img src={successCheckbox} height="44" />
            </div>
            <Typography variant="header4" className="flex center column" align="center">
                Complete.
            </Typography>

            <Typography className="flex" variant="body1" align="center" >
                Your feedback is being reviewed by your Regional Office. Thanks so much for your help!
            </Typography>

            <div className={'flex center'}>
                <Button 
                    variant={'primary'} 
                    onClick={() => history.push(`/question/${firstQuestion?.Id}${location.search}`)}
                >
                    {'Review answers'}
                </Button>
            </div>

        </div>
    </>
}
