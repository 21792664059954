import React, { useState, ReactElement, Children, ReactNode } from "react";
import { QuestionCard } from "./QuestionCard";
import { TextEntry } from "./TextEntry";
import { Loader, ValidationMessage, Button } from "@eftours/labs.ui-components";
import { Header } from "./Header";
import { useQuestionAPI, useQuestionIdSequence } from "@core/hooks";
import { useQuestionState, useApplicationState } from "@core/providers";
import { Redirect, useParams, useHistory, useLocation } from "react-router-dom";

export const QuestionPage: React.FunctionComponent = () => {

    const { questions, dispatch } = useQuestionState();
    const { state: { ProdTourId } } = useApplicationState();
    const { post } = useQuestionAPI();
    const { questionId } = useParams();
    
    const history = useHistory();
    //
    // In lieu of cookies, we need some sort of way to persist the query string
    // arguments across refreshes/navigations, so just keep it around when
    // navigating.
    const location = useLocation()

    if (!questionId) {
        return <Redirect to={{
            pathname: "/error",
            search: location.search
        }} />
    }

    const parsedQuestionId: number = parseInt(questionId)

    if (isNaN(parsedQuestionId)) {
        return <Redirect to={{
            pathname: "/error",
            search: location.search
        }} />
    }

    const [state, setState] = useState({ isSubmitting: false, hasError: false });

    const currentQuestion = questions[parsedQuestionId] || null

    const { previousQuestionId, nextQuestionId } = useQuestionIdSequence(parsedQuestionId)

    if (!currentQuestion) {
        return <Redirect to={{
            pathname: "/error",
            search: location.search
        }} />
    } else {
        const markComplete = async () => {

            try {
                setState({ ...state, isSubmitting: true })

                await post(currentQuestion.Id);
                dispatch({ type: "Mark_Complete", data: { id: currentQuestion.Id } });

                setState({ isSubmitting: false, hasError: false })

                if(nextQuestionId) {
                    history.push(`/question/${nextQuestionId}${location.search}`)
                } else {
                    history.push(`/success${location.search}`)
                }
            } catch (error) {
                setState({ isSubmitting: false, hasError: true })
            }

        }

        return <>
            <Header 
                currentQuestionId={parsedQuestionId}
                sortedQuestionIds={Object.values(questions).map(q => q.Id)}
                prodTourId={ProdTourId}
            />
            <QuestionCard question={currentQuestion} />
            {currentQuestion.Type !== "Text" && <TextEntry {...currentQuestion} />}
            {(state.hasError) &&
                <ValidationMessage messageType="error">
                    Could Not Submit, try again later.
                </ValidationMessage>
            }
            <SurveyQuestionButtonWrapper>
                {(previousQuestionId) && 
                    <SurveyQuestionButton
                        onClick={() => history.push(`/question/${previousQuestionId}${location.search}`)}
                        loading={state.isSubmitting}
                    >
                        {'Back'}
                    </SurveyQuestionButton>
                }
                <SurveyQuestionButton 
                    onClick={markComplete}
                    loading={state.isSubmitting}
                >
                    {(nextQuestionId) ? "Next" : "Finish"}
                </SurveyQuestionButton>
            </SurveyQuestionButtonWrapper>
        </>
    }
}

function SurveyQuestionButton({
    loading,
    children,
    ...rest
}: 
{
    loading: boolean
    children: string
} & React.ButtonHTMLAttributes<HTMLButtonElement>
): ReactElement {
    return (
        <Button 
            disabled={loading} 
            {...rest}
            variant="primary">
            {(loading) ? <Loader /> : children}
        </Button>
    )
}

function SurveyQuestionButtonWrapper({
    children
}:{
    children: ReactNode
}):  ReactElement {

    const childrenCount = Children.toArray(children).filter(child => child).length

    return (
        <div className={ `flex ${( childrenCount > 1) ? 'space-between': 'end'}`}>
            {children}
        </div>
    )
}

