import { useQuestionState, QuestionLookup} from "@core/providers"
import { useMemo } from 'react'

export const useQuestionIdSequence = (currentId: number) => {
    const { questions } = useQuestionState();
    const memoizedSort = useMemo(() => getSortedQuestionSequence(questions), [questions])

    const questionsSorted = memoizedSort.map(id => questions[id]);

    return {
        questionIdSequence: questionsSorted,
        nextQuestionId: getNextQuestionId(memoizedSort, currentId),
        previousQuestionId:  getPreviousQuestionId(memoizedSort, currentId)
    };
}

function getSortedQuestionSequence(questions: QuestionLookup): number[] {
    let sortedSequence = Object.values(questions).sort(q => q.SortOrder).map(q => q.Id)

    return sortedSequence
}

function getNextQuestionId(sortedSequence: number[], id: number): number | null {
    let currentIndex = sortedSequence.indexOf(id)

    if(currentIndex === sortedSequence.length - 1) return null

    return sortedSequence[currentIndex + 1]
}

function getPreviousQuestionId(sortedSequence: number[], id: number): number | null {
    let currentIndex = sortedSequence.indexOf(id)

    if(currentIndex === 0) return null

    return sortedSequence[currentIndex - 1]
}

