import { Button } from "@eftours/labs.ui-components"
import React from "react";

interface IProps {
    isSelected?: boolean;
    onSelected: () => void;
}

export const ScaleButton: React.FunctionComponent<IProps> =
    ({ children, isSelected, onSelected }) => {
        if (isSelected) {
            return <>
                <Button isBold onClick={onSelected} className="scale-button selected" variant="white" >
                    {children}
                </Button>
            </>
        } else {
            return <>
                <Button isBold onClick={onSelected} className="scale-button" variant="white" >
                    {children}
                </Button>
            </>
        }
    }