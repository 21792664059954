import React from "react";
import { Card, CardContent, Typography } from "@eftours/labs.ui-components"
import { FourScale } from "./FourScale";
import { BinaryChoice } from "./BinaryChoice/BinaryChoice";
import { TextEntry } from "./TextEntry";
import { Question } from "@core/Question";

interface IProps {
    question: Question
}

export const QuestionCard: React.FunctionComponent<IProps> = ({ question }) => {

    const renderControl = () => {
        switch (question.Type) {
            case 'FourScale':
                return (
                    <div className="card-footer shaded">
                        <FourScale {...question} />
                    </div>
                )
            case 'BinaryChoice':
                return (
                    <div className="card-footer shaded">
                        <BinaryChoice {...question} />
                    </div>
                )
            case 'Text':
                return (
                    <div className="card-footer">
                        <TextEntry {...question} />
                    </div>
                )
        }

    }


    return <>
        <Card outline>
            <CardContent>
                <Typography variant="body1">
                    {question.Text}
                </Typography>
            </CardContent>
            {renderControl()}
        </Card>
    </>
}