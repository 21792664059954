import React, { useEffect, useState } from "react";
import { FormWrapper, Loader } from "@eftours/labs.ui-components";
import { QuestionProvider, ApplicationStateProvider, useApplicationState, useQuestionState } from "@core/providers";
import { QuestionPage } from "./QuestionPage";
import { SuccessLayout } from "./SuccessPage";
import { ErrorPage } from "./ErrorPage";
import { useQuestionAPI, useLastAnsweredQuestion } from "@core/hooks";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";


export const Layout: React.FunctionComponent = () => {
    return <>
        <ApplicationStateProvider>
            <QuestionProvider>
                <FormWrapper>
                    <Application />
                </FormWrapper>
            </QuestionProvider>
        </ApplicationStateProvider>
    </>
}
export const Application: React.FunctionComponent = () => {
    const { fetch } = useQuestionAPI();
    const { state: { LoginName, ProdTourToken } } = useApplicationState();

    const [hasLoaded, setHasLoaded] = useState(false);

    const lastAnsweredQuestion = useLastAnsweredQuestion();

    useEffect(() => {
        if (LoginName && ProdTourToken) {
            // Fetch the questions
            fetch().then(() => {
                setHasLoaded(true);
            });
        }
    }, [LoginName, ProdTourToken])

    if(hasLoaded) {
        return <BrowserRouter>
            <Switch>
                <Route exact path="/" render={routeProps => {
                    if (lastAnsweredQuestion) {
                        return <Redirect to={{
                            pathname:`/question/${lastAnsweredQuestion.Id}`,
                            search: routeProps.location.search 
                        }}/>
                    } else {
                        return <Redirect to={{
                            pathname:'/success',
                            search: routeProps.location.search 
                        }}/>
                    }
                }}/>
                <Route path="/question/:questionId">
                    <QuestionPage />
                </Route>
                <Route path="/success">
                    < SuccessLayout />
                </Route>
                <Route path="/error">
                    <ErrorPage />
                </Route>
            </Switch>
        </BrowserRouter>
    } else {
        return <Loader />
    }
}
