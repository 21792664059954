import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from 'react-i18next';
import translationEngUs from "../i18n/en-US/translations.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    // we init with resources
    resources: {
      'en-US': {
        translations: translationEngUs
      }
    },
    fallbackLng: "en-US",
    
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
    nonExplicitWhitelist: true,
    // lng: 'en-US',

    keySeparator: '.',

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ","
    },
    // Language Detector Options https://github.com/i18next/i18next-browser-languageDetector
    detection: {
      lookupQuerystring: 'l',
    },

    react: {
      wait: true,
      defaultTransParent: 'span'
    }
  });

export default i18n;
