import React, { useContext, useState, useEffect } from "react";

interface IApplicationState {
    LoginName: string;
    ProdTourToken: string;
    IndividualId: number;
    ProdTourId: number;
    PaxTypeCode: string;
    SalesTourId: string;
}

interface IApplicationStateContext {
    setRuntimeValues: (vals: RuntimeValues) => void;
    state: IApplicationState;
}

interface RuntimeValues {
    individualId: number;
    prodTourId: number;
    paxTypeCode: string;
    salesTourId: string;
}

const defaultValues: IApplicationState = {
    LoginName: '',
    ProdTourToken: '',
    IndividualId: 0,
    ProdTourId: 0,
    PaxTypeCode: '',
    SalesTourId: '',
}

export const ApplicationStateContext = React.createContext<IApplicationStateContext>({
    state: { ...defaultValues },
    setRuntimeValues: () => { }
});

export const ApplicationStateProvider: React.FC<Partial<IApplicationState>> = ({ children, ...rest }) => {

    const [state, setState] = useState<IApplicationState>({ ...defaultValues, ...rest });

    const setRuntimeValues = ({ individualId, prodTourId, paxTypeCode, salesTourId }: RuntimeValues) => {
        setState({
            ...state,
            IndividualId: individualId,
            ProdTourId: prodTourId,
            PaxTypeCode: paxTypeCode,
            SalesTourId: salesTourId
        });
    }


    // Empty braces here will prevent this from running more than once
    // If you wanted it to run whenever the query string changes, put `document.location.search`
    // in the braces (but since the query string would only change on refresh, it wouldn't change anything)
    useEffect(() => {
        const params = new URLSearchParams(document.location.search);
        // const tourId = params.get('prodTourId');
        const prodTourToken = params.get('prodTourToken');
        const tdLoginName = params.get('tdLoginName');
        if (prodTourToken && tdLoginName) {
            setState({ ...state, LoginName: tdLoginName, ProdTourToken: prodTourToken });
        }
    }, []);


    return (
        <ApplicationStateContext.Provider value={{ state, setRuntimeValues }}  >
            {children}
        </ApplicationStateContext.Provider>
    );
}

export const useApplicationState = () => useContext(ApplicationStateContext);
