
export enum BinaryChoiceOptions {
    No = 0,
    Yes = 1
}

export const BinaryChoiceOptionsList = [
    BinaryChoiceOptions.Yes,
    BinaryChoiceOptions.No,
]
export const BinaryChoiceOptionsLabels = {
    [BinaryChoiceOptions.Yes] : 'Yes',
    [BinaryChoiceOptions.No] : 'No',
}