import axios from 'axios';
import { useApplicationState, useQuestionState, QuestionLookup } from '@core/providers';
import { GetQuestionResponse, PostQuestionRequest, AnswerTypeMap } from '@core/api';

export const useQuestionAPI = () => {
    const { state: { LoginName, IndividualId, ProdTourToken, ProdTourId, SalesTourId, PaxTypeCode }, setRuntimeValues } = useApplicationState();
    const { dispatch, questions } = useQuestionState();

    const fetch = async () => {

        const result = await axios.get<GetQuestionResponse>(`/tdeval/td/${LoginName}/`, {
            headers: { 'Authorization': `Bearer ${ProdTourToken}` }
        })

        setRuntimeValues({
            individualId: result.data.TourDirectorSurvey.Individual_id,
            prodTourId: result.data.TourDirectorSurvey.ProdTour_id,
            paxTypeCode: result.data.TourDirectorSurvey.PaxTypeCode,
            salesTourId: result.data.TourDirectorSurvey.SalesTour_id
        });

        const questions = result.data.TourDirectorSurvey.Questions.reduce((prev, curr) => {
            prev[curr.Question_id] = {
                Id: curr.Question_id,
                Completed: false,
                Text: curr.QuestionLabel,
                SortOrder: curr.SortOrder,
                Type: AnswerTypeMap(curr.AnswerType),
            }
            return prev;
        }, {} as QuestionLookup);

        result.data.Answers.forEach((value) => {
            const question = questions[value.Question_id]
            question.Comments = value.Comment;
            if (question.Type === "BinaryChoice") {
                // This enum already map correctly
                question.Answer = value.Score;
            } else if (question.Type === "FourScale") {
                // This enum already map correctly
                question.Answer = value.Score;
            }
            // If we have an answer value, we can mark the question as completed.
            question.Completed = true;
        });

        dispatch({ type: 'Set_Questions', data: questions })
        return result.data;

    }
    const post = async (questionId: number) => {

        const question = questions[questionId];

        let skipped = true;

        let score: number | undefined;
        if (question.Type !== "Text") {
            score = question.Answer;
            if (score !== undefined) {
                skipped = false;
            }
        } else {
            skipped = !(question.Comments && question.Comments.trimLeft());
        }

        const request: PostQuestionRequest = [{
            Skipped: skipped,
            Question_id: question.Id,
            Individual_id: IndividualId,
            Comment: question.Comments || '',
            Score: score,
            PaxTypeCode: PaxTypeCode,
            ProdTour_id: ProdTourId,
            SalesTourId: SalesTourId,
            // seems to be ignored
            Evaluation_id: -1,
            // Only matter for component evaluations
            ComponentRan: true,
            ServiceRecovery: false,
        }]




         const result = await axios.post(`/tdeval/`, request, {
             headers: { 'Authorization': `Bearer ${ProdTourToken}` }
         });

        return result.status < 400;
    }

    return { fetch, post }
}
