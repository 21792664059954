import React from "react";
import { RadioGroup, Radio } from "@eftours/labs.ui-components"
import { BinaryChoiceOptions, BinaryChoiceOptionsLabels } from "@core/BinaryChoiceOptions"
import { BinaryChoiceQuestion } from "@core/Question";
import { useQuestionState } from "@core/providers";


export const BinaryChoice: React.FunctionComponent<BinaryChoiceQuestion> = ({ Answer, Id }) => {
    const { dispatch } = useQuestionState();

    const updateAnswer = (selection: string) => {
        // The radios use have to use a string value (html limitation)
        // and the .toString() of our enum is and integer
        let answer = parseInt(selection);
        dispatch({ type: "Update_Answer_BinaryChoice", data: { id: Id, answer } })
    }

    return <>
        <div className="binary-choice-holder" data-testid="binary-choice">
            <RadioGroup name="binary-choice"
                value={`${Answer}`}
                onChange={(e: any) => updateAnswer(e.target.value)}>
                <div className="row">
                    <Radio value={BinaryChoiceOptions.Yes.toString()}
                        title={BinaryChoiceOptionsLabels[BinaryChoiceOptions.Yes]} />
                    <Radio value={BinaryChoiceOptions.No.toString()}
                        title={BinaryChoiceOptionsLabels[BinaryChoiceOptions.No]} />
                </div>
            </RadioGroup>
        </div>
    </>
}