import "./style/index.scss";
import * as React from 'react';
import { render } from 'react-dom';
import { Layout } from './components/Layout';
import './i18n';
import axios from "axios";

axios.defaults.baseURL = process.env.API_URL;



render(<Layout />, document.getElementById('main'));