export enum FourScaleOptions {
    Poor = 1,
    Fair = 2,
    Good = 3,
    Excellent = 4
}

export const FourScaleOptionsList = [
    FourScaleOptions.Poor,
    FourScaleOptions.Fair,
    FourScaleOptions.Good,
    FourScaleOptions.Excellent
]

export const ScaleOptionLabels = {
    [FourScaleOptions.Excellent]: 'Excellent',
    [FourScaleOptions.Good]: 'Good',
    [FourScaleOptions.Fair]: 'Fair',
    [FourScaleOptions.Poor]: 'Poor',
}